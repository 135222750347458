import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "./logo"
import useCheckRendered from "../hooks/checkRendered"
import SlideoutMenu from "./SlideoutMenu"
import BurgerButton from "./BurgerButton"
import { laptop, large } from "../util/mediaQueries"
import { Media } from "gatsby-plugin-fresnel"

const HeaderRoot = styled.header`
  background: #4d6888;
  opacity: ${props => props.theme.opacity};
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
  color: white;
  z-index: 2;
`

const HeaderDiv = styled.div`
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  @media ${large} {
    max-width: 1200px;
    margin: 0 auto;
  }
  justify-content: space-between;
  align-items: center;
  height: 44px;
  @media ${laptop} {
    height: 60px;
    padding-left: 0;
  }
`

const TitleLink = styled(Link)`
  text-decoration: none;
`

const PageSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  &:hover {
    margin-bottom: 17px;
    border-bottom: 3px solid rgba(255, 255, 255, 0.8);
  }
`

const SelectionText = styled.span`
  font-size: 20px;
  white-space: nowrap;
`

/**/
const HeaderMobile = () => {
  const [dropOpen, setDropOpen] = useState(false)

  const rendered = useCheckRendered()

  return (
    <HeaderRoot>
      <HeaderDiv>
        <TitleLink to="/">
          <Logo />
        </TitleLink>
        {rendered && (
          <BurgerButton
            onClick={() => setDropOpen(!dropOpen)}
            isActive={dropOpen}
          />
        )}
      </HeaderDiv>
      <SlideoutMenu open={dropOpen} />
    </HeaderRoot>
  )
}

const PageSelectorItem = (props: { title: string; to: string }) => {
  return (
    <StyledLink
      to={props.to}
      activeStyle={{ borderBottom: "3px solid #a7b5c5", marginBottom: "17px" }}
    >
      <SelectionText>{props.title}</SelectionText>
    </StyledLink>
  )
}

const HeaderLarge = () => {
  return (
    <HeaderRoot>
      <HeaderDiv>
        <TitleLink to="/">
          <Logo />
        </TitleLink>
        <PageSelection>
          <PageSelectorItem title="Über uns" to="/ueber-uns/" />
          <PageSelectorItem title="Leistungen" to="/leistungen/" />
          <PageSelectorItem title="Karriere" to="/karriere/" />
          <PageSelectorItem title="Downloads" to="/downloads/" />
          <PageSelectorItem title="Steuernews" to="/steuernews/" />
        </PageSelection>
      </HeaderDiv>
    </HeaderRoot>
  )
}

const Header = () => (
  <>
    <Media greaterThanOrEqual="lg">
      <HeaderLarge />
    </Media>
    <Media lessThan="lg">
      <HeaderMobile />
    </Media>
  </>
)

export default Header
