import React from "react"
import Burger from "react-css-burger"
import { Media } from "gatsby-plugin-fresnel"

const BurgerButton = ({ onClick, isActive }) => {

    return (
        <>
            <Media at="md">
                <Burger
                    onClick={onClick}
                    active={isActive}
                    burger="squeeze"
                    color="white"
                    marginTop="0px"
                    marginBottom="0px"
                    marginRight="10px"
                    scale={1.0}
                />
            </Media>
            <Media at="sm">
                <Burger
                    onClick={onClick}
                    active={isActive}
                    burger="squeeze"
                    color="white"
                    marginTop="0px"
                    marginBottom="0px"
                    marginRight="10px"
                    scale={0.8}
                />
            </Media>

        </>
    )
}

export default BurgerButton
