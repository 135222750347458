import React from "react"
import styled from "styled-components"
import Text, { TextType } from "./text"
import { Link } from "gatsby"
import { tablet } from "../util/mediaQueries"
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi"

const RootDiv = styled.div`
    max-height: ${props => props.open ? "500px" : "0px"};
    transition: max-height 1s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`



const SlideoutLink = styled(Link)`
    text-decoration: none;
    color: white;
    margin: 20px;
`

const SlideoutMenuItem = (props: { title: string, to: string }) => (
    <SlideoutLink to={props.to}>
        <Text type={TextType.SubHeader}>
            {props.title}
        </Text>
    </SlideoutLink>
)

const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px;
    justify-content: flex-end;
`
const ButtonDiv = styled.div`
    width: 30px;
    height: 30px;
    @media ${tablet} {
        width: 40px;
        height: 40px;
    }
    margin-left: 40px;
`

const SlideoutMenu = (props: { open: boolean }) => (
    <RootDiv open={props.open}>
        <SlideoutMenuItem title="Über uns" to="/ueber-uns/" />
        <SlideoutMenuItem title="Leistungen" to="/leistungen/" />
        <SlideoutMenuItem title="Karriere" to="/karriere/" />
        <SlideoutMenuItem title="Downloads" to="/downloads/" />
        <SlideoutMenuItem title="Steuernews" to="/steuernews/" />
        <ButtonsRow>
            <ButtonDiv>
                <a href="tel:+498982085970">
                    <FiPhone size="40px" color="white" />
                </a>
            </ButtonDiv>
            <ButtonDiv>
                <a href="mailto:info@dietrich-suess.de">
                    <FiMail size="40px" color="white" />
                </a>
            </ButtonDiv>
            <ButtonDiv>
                <a href="https://www.google.com/maps/search/?api=1&query=dietrich+und+suess+steuerberater&query_place_id=ChIJtcQWP3XYnUcRFtmE" >
                    <FiMapPin size="40px" color="white" />
                </a>
            </ButtonDiv>
        </ButtonsRow>
    </RootDiv>
)

export default SlideoutMenu
