import React from "react"
import styled from "styled-components"
import Text, { TextType } from "./text"
import { phone } from "../util/mediaQueries"

const Link = styled.a`
  text-decoration: none;
  color: ${props => (props.white ? "white" : props.theme.textColor)};
`

const Mail = ({ mail, white }: { mail: string; white?: boolean }) => (
  <Link href={`mailto:${mail}`} white={white}>
    <Text type={TextType.Body}>{mail}</Text>
  </Link>
)

export default Mail
