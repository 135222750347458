import React from "react"
import styled from "styled-components"

const TextSpan = styled.span`
    font-size: ${props => props.theme.headerSize};
    font-style: italic;
    color: white;
    white-space: nowrap;
`
const AmpersandSpan = styled.span`
    color: ${props => props.theme.third};
`

const Logo = () => (
    <TextSpan>
        Dietrich,  Süß <AmpersandSpan>&</AmpersandSpan> Jaschke
    </TextSpan>
)

export default Logo
