import { useEffect, useState } from "react"

const useCheckRendered = () => {
    const [rendered, setRendered] = useState(false)

    useEffect(() => {
        setRendered(true)
    })

    return rendered
}

export default useCheckRendered
