import React from "react"
import styled from "styled-components"
import ContentDiv from "./contentDiv"
import Map from "../components/map"
import Logo from "./logo"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Text, { TextType } from "./text"
import Mail from "./mail"
import { laptop, tablet } from "../util/mediaQueries"
import PhoneNumber from "./PhoneNumber"
import { Media } from "gatsby-plugin-fresnel"

const RootDiv = styled.div`
  margin-top: 160px;
  position: relative;
  color: white;
  width: 100%;
  @media ${laptop} {
    height: 500px;
  }
`

const DataColDiv = styled(ContentDiv)`
  position: absolute;
  left: 0;
  right: 0;
  background: ${props => props.theme.main};
  flex-direction: column;
  justify-content: center;
  display: flex;
  z-index: 1;
  opacity: 0.95;
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  @media ${laptop} {
    margin: auto;
    border-radius: 10px;
  }
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    flex-direction: row;
  }
  justify-content: space-between;
  padding-bottom: 40px;
`

const LogoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 40px;
`

const DataContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  @media ${tablet} {
    width: 30%;
    margin-bottom: 0;
  }
`

const MapDiv = styled(AnchorLink)`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 80px;
  width: 100%;
`

const StyledLink = styled(Link)`
  color: white;
  font-size: ${props => props.theme.textSize};
`

const FooterMobile = () => (
  <RootDiv>
    <DataColDiv>
      <LogoRow>
        <Logo />
      </LogoRow>
      <InfoRow>
        <DataContentDiv>
          <Text type={TextType.Body}>
            <Mail mail="info@dietrich-suess.de" white /> <br />
            <PhoneNumber numPre="89" numSuf="82085970" white />
          </Text>
        </DataContentDiv>
        <DataContentDiv>
          <Text type={TextType.Body}>
            Landsberger Straße 408
            <br />
            81241 München
          </Text>
        </DataContentDiv>
        <DataContentDiv>
          <StyledLink to="/impressum">Impressum</StyledLink>
          <StyledLink to="/datenschutz">Datenschutz</StyledLink>
        </DataContentDiv>
      </InfoRow>
    </DataColDiv>
  </RootDiv >
)

const FooterLarge = () => (
  <RootDiv>
    <DataColDiv>
      <LogoRow>
        <Logo />
      </LogoRow>
      <InfoRow>
        <DataContentDiv>
          <Text type={TextType.Body}>
            <Mail mail="info@dietrich-suess.de" white /> <br />
            <PhoneNumber numPre="89" numSuf="82085970" white />
          </Text>
        </DataContentDiv>
        <DataContentDiv>
          <StyledLink to="/impressum">Impressum</StyledLink>
          <StyledLink to="/datenschutz">Datenschutz</StyledLink>
        </DataContentDiv>
        <DataContentDiv>
          <Text type={TextType.Body}>
            Landsberger Straße 408
            <br />
            81241 München
          </Text>
        </DataContentDiv>
      </InfoRow>
    </DataColDiv>
    <MapDiv to="/ueber-uns#anfahrt">
      <Map height={600} topHidden />
    </MapDiv>
  </RootDiv >
)


const Footer = () => {
  return (
    <>
      <Media greaterThanOrEqual="lg">
        <FooterLarge />
      </Media>
      <Media lessThan="lg">
        <FooterMobile />
      </Media>
    </>
  )
}

export default Footer
