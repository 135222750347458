/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import Header from "./header"
import "fontsource-open-sans"
import Footer from "./footer"
import SideButtons from "./sideButtons"
import Theme from "../theme"
import { Media } from "gatsby-plugin-fresnel"

const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.textColor};
  font-size: 20px;
  height: 100%;
  width: 100%;
  hyphens: none;
`

const LayoutDiv = styled.div`
  font-family: Open Sans;
  width: 100%;
  height: 100%;
`

interface Props {
  withoutMap?: boolean
  children: React.ReactNode
}


const Layout = (props: Props) => {
  return (

    <LayoutDiv>

      <Media lessThan="lg">
        <ThemeProvider theme={Theme({ phone: true })}>
          <Header />
          <PageDiv>{props.children}</PageDiv>
          <Footer />
        </ThemeProvider>
      </Media>
      <Media greaterThanOrEqual="lg">
        <ThemeProvider theme={Theme({ phone: false })}>
          <Header />
          <SideButtons />
          <PageDiv>{props.children}</PageDiv>
          <Footer />

        </ThemeProvider>
      </Media>
    </LayoutDiv>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
