import React from "react"
import styled from "styled-components"
import { phone, tablet } from "../util/mediaQueries"
import Text, { TextType } from "./text"

const StyledLink = styled.a`
  color: ${props => (props.white ? "white" : props.theme.textColor)};
  text-decoration: none;
  font-size: 20px;
  @media ${tablet} {
    pointer-events: none;
  }
`

const PhoneNumber = ({
  numPre,
  numSuf,
  white,
}: {
  numPre: string
  numSuf: string
  white?: boolean
}) => (
  <StyledLink href={`tel:+49${numPre}${numSuf}`} white={white}>
    <Text type={TextType.Body}>{`0${numPre}/${numSuf}`}</Text>
  </StyledLink>
)

export default PhoneNumber
