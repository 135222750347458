import styled from "styled-components"
import { laptop } from "../util/mediaQueries"

const ContentDiv = styled.div`
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  @media ${laptop} {
    padding-right: 0;
    padding-left: 0;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    width: 1200px;
  }
`

export default ContentDiv
