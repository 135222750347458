import React from "react"
import styled from "styled-components"
import "../../node_modules/leaflet/dist/leaflet.css"

const GoogleURL = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10648.67288849994!2d11.4815826!3d48.145562!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4c3f3f02f984d916!2sDietrich%20und%20S%C3%BC%C3%9F%20Steuerberater!5e0!3m2!1sde!2sde!4v1629133449605!5m2!1sde!2sde"

const MapDiv = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: ${props => props.height};
`

const Map = ({ height, topHidden }: { height: number, topHidden?: boolean }) => {

    return (
        <MapDiv height={`${height}px`}>
            <iframe src={GoogleURL} width="100%" height={topHidden ? `${height + 120}px` : `${height}px`} style={{ border: 0, marginTop: topHidden ? "-120px" : "none", }}></iframe>
        </MapDiv>
    )
}

export default Map
