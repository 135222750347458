import React from "react"
import { FiMail, FiPhone } from "react-icons/fi"
import styled from "styled-components"
import Mail from "./mail"
import PhoneNumber from "./PhoneNumber"

const RootDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 2;
`

const SliderDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px 0px 0px 10px;
    opacity: ${props => props.theme.opacity};
    backdrop-filter: blur(8px);
    background: #4d6888;
    margin-bottom: 20px;
    max-width: 50px;
    &:hover {
        max-width: 300px;
    }
    transition: max-width 1s;
    align-self: flex-end;
`

const ButtonDiv = styled.div`
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin: 10px;
`

const TextSpan = styled.div`
    font-size: ${props => props.theme.textSize};
    color: white;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
`

const SideButtons = () => {

    return (
        <RootDiv>
            <SliderDiv>
                <ButtonDiv ><FiPhone size="30px" color="white" /></ButtonDiv>
                <TextSpan >
                    <PhoneNumber numPre="89" numSuf="82085970" white />
                </TextSpan>
            </SliderDiv>
            <SliderDiv>
                <ButtonDiv ><FiMail size="30px" color="white" /></ButtonDiv>
                <TextSpan>
                    <Mail mail="info@dietrich-suess.de" white />
                </TextSpan>
            </SliderDiv>
        </RootDiv >
    )
}

export default SideButtons
